import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
const app = createApp(App);

app.use(createPinia());

app.component('new-memberform', require('./components/New-memberform.vue').default);
app.component('member-login', require('./components/Member-login.vue').default);
app.component('support-form', require('./components/Support-form.vue').default);
app.component('contact-form', require('./components/Contact-form.vue').default);
app.component('price-calculator', require('./components/Price-calculator.vue').default);
app.component('order-help', require('./components/Order-experthelp.vue').default);
app.component('online-demo', require('./components/Online-demo.vue').default);

app.mount('#app');
