<template>
    <div v-if="data.forgotPassword == false" class="umb-element">
        <form method="post" @submit.prevent="submit" class="default-form">
            <div class="center-text">
              <h2>{{ getText('Login') }}</h2>
            </div>
            <div class="form-group">
                <input type="text" name="email" required :placeholder='getText("Email")'/>
            </div>
            <div class="form-group"> 
                <input class="show-password" type="password" name="password" required :placeholder='getText("Password")'/>
            </div>
            <div class="form-group"> 
                <input type="checkbox" name="persistent"/> {{ getText("Stay logged in") }}
            </div>
            <div v-if="data.errorText != null" class="error-messages">
                <div class="error-message">{{getText(data.errorText) }}</div>
            </div>
            <div class="form-group"> 
                <div class="link" v-on:click="ToggleForgotPassword()">{{ getText("Don't remember your password?") }}</div> 
            </div>
            <div class="form-group center-text">
                <input type="submit" :value="getText('Login')" class="btn" />
            </div>
        </form>
    </div>
    <div v-else class="umb-element">
      <form method="post" @submit.prevent="submit" class="default-form">
            <div class="center-text">
              <h2>{{ getText('Request new password') }}</h2>
            </div>
            <div class="form-group">
                <input type="text" name="email" required :placeholder='getText("Email")'/>
            </div>
            <div class="form-group"> 
                <div class="link" v-on:click="ToggleForgotPassword()">{{ getText("Back to login") }}</div> 
            </div>
            <div class="form-group center-text">
                <input type="submit" :value="getText('Login')" class="btn" />
            </div>
        </form>
    </div>
</template>
<script setup lang="js">
import { onMounted, reactive } from 'vue'
import MemberApi from "../apis/member";
import { useDictionaryStore } from '@/stores/dictionary'
const dictionaryStore = useDictionaryStore()

const data = reactive({
    memberData: null,
    persistent: false,
    errorText: null,
    forgotPassword: false
});

function ToggleForgotPassword()
{
  data.forgotPassword = !data.forgotPassword
}

function getText (key) {
  return dictionaryStore.getText(key);
}

function submit(event) {
  if(event != null)
  {
    const {email, password, persistent} = Object.fromEntries(new FormData(event.target));
    data.email = email;
    data.password = password;
    data.persistent = persistent != undefined && persistent === "on";
    data.hostname = window.location.hostname;

    MemberApi.LogIn({
      email: data.email,
      password: data.password,
      rememberMe: data.persistent,
      lockOutOnFailure: false
    }).then(response => {
      if(response.data.error == null)
      {
          
          data.memberData = response.data;
          if(data.memberData != null)
          {
              window.location.href = "/"
          }
      }
      else
      {
          data.errorText = response.data.error.message; 
      }
  });
  }
}

onMounted(() => {
  dictionaryStore.loadDictionary();
})
</script>

<style scoped lang="css">
  .show-password {
    z-index: 1000;
    cursor: pointer;
    padding-right: 15px;

    background-size: 20px;
  }
</style>