<template>
  <div class="stepper">
    <ol class="stepper">
      <li :class="getClass(1)">Opret</li>
      <li :class="getClass(2)">Aktiver</li>
      <li :class="getClass(3)">Kodeord</li>
      <li :class="getClass(4)">Oprettet</li>
    </ol>
  </div>
  <div v-if="!data.tempMemberCreated && data.step == 1" class="umb-element">
    <form method="post" @submit.prevent="submitNewAccount" class="default-form">
      <div class="title">
        <h2>{{ getText('Inbound Analyzer account') }}</h2>
      </div>
      <div class="center">
        {{getText('Step 1 help')}}
      </div>  
      <div class="form-group">
        <label for="companyname">{{getText('Company name', false)}}</label>
        <input type="text" name="companyName" id="companyname" required :placeholder="getText('Enter companyname')" />
      </div>
      <div class="form-group">
        <label for="name">{{getText('Enter name', false)}}</label>
        <input type="text" name="name" id="name" required :placeholder="getText('Enter name')" />
      </div> 
      <div class="form-group">
        <label for="email">{{getText('Email', false)}}</label>
        <input type="text" name="email" id="email" minlength="3" required :placeholder="getText('Email')" />
      </div>
      <div class="form-group">
        <input type="checkbox" name="subscibeToNewsLetter" id="subscibeToNewsLetter" /> 
        <label for="subscibeToNewsLetter">{{ getText('Subscribe to newsletter',false) }}</label>
      </div>
      <div class="form-group consent">
        <input type="checkbox" name="subscriptionConsent" id="subscriptionConsent" required /> 
        <label for="subscriptionConsent"><a v-bind:href="props.subscriptionconcenturl" target="_blank">{{ getText('Accept subscription consent') }}</a></label>
      </div>
      <div class="form-group consent">
        <input type="checkbox" name="dataConsent" id="dataConsent" required /> 
        <label for="dataConsent"><a v-bind:href="props.dataconsenturl" target="_blank">{{ getText('Accept data consent') }}</a></label>
      </div>
      <div v-if="data.errorText != null" class="error-messages">
        <div v-if="data.translateErrorText" class="error-message">{{ getText(data.errorText) }}</div>
        <div v-if="!data.translateErrorText" class="error-message">{{ data.errorText }}</div>
      </div>
      <div class="form-group center-text">
        <input type="submit" :value="getText('Create account')" class="btn" />
      </div>
    </form>
  </div>
  <div v-else-if="data.step == 2" class="new-member-form center">
    <div>
      <form method="post" @submit.prevent="submitActivationCode" class="new-member-form">
        <h2>{{ getText('We have sent you an email') }}</h2>
        <div class="center">
        {{getText('Step 2 help')}}
        </div>  
        <input type="hidden" name="email" :value="data.email" />
        <div class="form-group">
            <input type="text" name="inputActivationCode" minlength="6" class="center" required :placeholder="getText('Enter activationcode')" />
        </div>
        <div v-if="data.errorText != null" class="error-messages">
            <div class="error-message">{{ getText(data.errorText) }}</div>
        </div>
        <div class="form-group">
            <input type="submit" :value="getText('Activate account')" class="btn" />
        </div>
      </form>
    </div>
  </div>
  <div v-else-if="data.step == 3" class="new-member-form center">
    <div>
      <form method="post" @submit.prevent="submitSavePassword" class="new-member-form">
        <h2>{{ getText('Set password for you new account') }}</h2>
        <div class="center">
        {{getText('Step 3 help')}}
        </div>  
          <input type="hidden" name="email" :value="data.email" />
          <div class="form-group">
              <input type="password" name="inputPassword" minlength="10" class="center" required :placeholder="getText('Choose a password')" />
          </div>
          <div v-if="data.errorText != null" class="error-messages">
              <div class="error-message">{{ data.errorText }}</div>
          </div>
          <div class="form-group">
              <input type="submit" :value="getText('Save password')" class="btn" />
          </div>
      </form>
    </div>
  </div>
  <div v-else-if="data.step == 4 && data.umbracoAccountCreated" class="default-form center">
    <h2>{{ getText('Your account is created') }}</h2>
    <div class="center">
        {{getText('Step 4 help')}}
    </div>  
    <a target="_blank" :href="getText('App url')">{{ getText('Go to inbound analyzer app') }}</a>
  </div>
</template>
<script setup lang="js">
import MemberApi from "../apis/member";
import { onMounted, reactive, defineProps } from 'vue'
import { useDictionaryStore } from '@/stores/dictionary'
const dictionaryStore = useDictionaryStore()

const props = defineProps(['subscriptionconcenturl', 'dataconsenturl']);

const url = new URL(window.location.href);

const data = reactive({
    companyName: '',
    name: '',
    email: null,
    errorText: null,
    tempMemberCreated: false,
    activationKey: null,
    step: 1,
    umbracoAccountCreated: false,
    translateErrorText: false
});

function getClass(aStep)
{
  if(aStep == data.step)
  {
    return "active";
  }
  else
  {
    return "";
  }
}

function getText (key) {
    return dictionaryStore.getText(key);
}

function submitNewAccount(event) {
    if(event != null)
    {
        const {companyName, name, email, subscriptionConsent, dataConsent, subscibeToNewsLetter } = Object.fromEntries(new FormData(event.target));
        data.name = name;
        data.companyName = companyName;
        data.email = email;
        data.subscriptionConsent = subscriptionConsent == "on" ? true : false;
        data.dataConsent = dataConsent == "on" ? true : false;
        data.subscibeToNewsLetter = subscibeToNewsLetter == "on" ? true : false;
        data.subscriptonGuid = url.searchParams.get("s");

        MemberApi.CreateTempMember({
          companyName: data.companyName, 
          name: data.name, 
          email: data.email, 
          subscriptionConsent: data.subscriptionConsent, 
          dataConsent: data.dataConsent,
          subscibeToNewsLetter: data.subscibeToNewsLetter,
          subscriptionUniqueId: data.subscriptonGuid,
        }).then(response => {
            if(response.data.error == null)
            {
                data.tempMemberCreated = true;
                data.errorText = null;
                data.step = 2;
            }
            else
            {
                data.errorText = response.data.error.message;
                data.translateErrorText = response.data.error.translate
            }
        });
    }
}

function submitActivationCode(event) {
    const { inputActivationCode, email } = Object.fromEntries(new FormData(event.target));

    MemberApi.ActivateTempMember({ activationcode:inputActivationCode, email:email}).then(response => {
            if(response.data.error == null)
            {                        
                data.errorText = null;
                if(!response.data.result.tempMemberExists)
                {
                    data.errorText = "The activationcode is incorrect";
                    data.activationKey = null;
                }
                else
                {
                    data.activationKey = response.data.result.activationKey;
                    data.step = 3;
                }
            }
            else
            {
                data.errorText = response.data.error.message;
            }
        });
}

function submitSavePassword(event) {
    const {email, inputPassword } = Object.fromEntries(new FormData(event.target));

    MemberApi.CreateMemberFromTemp({activationKey:data.activationKey,email:email,password:inputPassword}).then(response => {
            if(response.data.error == null)
            {
                data.umbracoAccountCreated = response.data.result.succeeded;
                data.errorText = null;
                data.step = data.umbracoAccountCreated ? 4 : 3;
                data.activationKey = null;
                data.email = null;
                //window.location.href = "/login?a=newaccount"
            }
            else
            {
                data.errorText = response.data.error.message;
            }
        });
}

onMounted(() => {
    dictionaryStore.loadDictionary();
})
</script>
<style lang="css">
ol.stepper {
  --default-b: lightgrey;
  --default-c: black;
  --active-b: rgba(0, 0, 30, 0.88);
  --active-c: white;
  --circle: 3.5em; /* size of circle */
  --b: 5px; /* line thickness */
}

ol.stepper {
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0;
  font-size: 1.1rem;
}

ol.stepper {
  counter-reset: step;
}
ol.stepper li::before {
  content: counter(step) " ";
  counter-increment: step;
}

ol.stepper li {
  overflow: visible;
  width: 90px;
  display: grid;
  place-items: center;
  gap: 5px;
}

ol.stepper li::before {
  content: counter(step) " ";
  counter-increment: step;
  display: grid;
  place-content: center;
  height: var(--circle);
  aspect-ratio: 1;
  background: var(--active-b);
  color: var(--active-c);
  border: 5px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
}

ol.stepper li.active ~ li::before{
  background: var(--default-b);
  color: var(--default-c);
}
</style>
<style lang="css">
form {
  margin-inline: auto;
  padding: 0.5em 2em;
  border-radius: 6px 6px 6px 6px !important;
  -webkit-border-radius: 6px 6px 6px 6px !important;
  -moz-border-radius: 6px 6px 6px 6px !important;
  border:1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  background-image: linear-gradient(180deg, rgb(255, 255, 255), rgb(248, 248, 248));
}

form .title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
}

form .btn {
  box-shadow:inset 0px 1px 0px 0px #54a3f7;
	background:linear-gradient(to bottom, #125a92 5%, #08263D 100%);
	background-color:#007dc1;
	border-radius:3px;
	border:1px solid #124d77;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	padding: 8px 8px;
	text-decoration:none;
	text-shadow:0px 1px 0px #154682;
}

form .consent {
  text-align: left !important;
  margin: 1em 0;
  line-height: 1.5;
}

form textarea {
  min-height: 300px;
}

form .form-group {
  color:#08263D;
  margin:1em  0;
  font-size: inherit;
  overflow: hidden;
}

form .form-group label {
  position: relative;
  font-weight: normal;
  line-height: 2;
  white-space: nowrap;
}

form .form-group input {
  font-size: inherit;
}

form .form-group input[type='text'], input[type='number'], input[type='tel'], input[type='password'], textarea {
  width:100%;
  font-size: inherit;
  padding: 6px  6px !important;
  background-color: #fafafa !important;
  border:1px solid #ddd !important;
  border-radius: 3px 3x 3px 3px !important;
  -webkit-border-radius: 3px 3x 3px 3px !important;
  -moz-border-radius: 3px 3x 3px 3px !important;
  font-size: inherit;
  margin-bottom: 8px !important;
}

form .form-group input[type='text']:focus, input[type='number']:focus, input[type='tel']:focus, input[type='password']:focus, textarea:focus
{
  border:1px solid #aaa !important;
  outline: #ddd !important;
}

form .form-group input[type='checkbox'] {
  position: relative;
  margin-left: 0;
  margin-right: 0.6em;
  cursor: pointer;
}

form .form-group input[type='checkbox']:before
{
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: 0;
  border:1px solid #aaa;
  background-color: white;
  border-radius: 3px 3x 3px 3px !important;
  -webkit-border-radius: 3px 3x 3px 3px !important;
  -moz-border-radius: 3px 3x 3px 3px !important;
}

.form-group input[type='checkbox']:checked:after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border:1px solid #666;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 5px;
}

form .form-group input[type='submit'] {
  width: 100%;
  font-size: inherit;
  margin-bottom: 8px;
}

::placeholder
{
    color:#aaa;
}

.default-form {
  max-width: 400px;   
}
</style>