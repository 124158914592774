<template>
  <div v-if="!data.mailSent" class="umb-element">
    <form method="post" @submit.prevent="submit" class="wide-form">
      <div class="title">
          <h2>{{getText('Contact form', false)}}</h2>
      </div>        
      <div class="form-group">
        <label for="companyname">{{getText('Company name', false)}}</label>
        <input type="text" name="companyname" id="companyname" :placeholder="getText('Company name', false)" />
      </div>
      <div class="form-group">
        <label for="name">{{getText('Name', true)}}</label>
        <input type="text" name="name" id="name" required :placeholder="getText('Name', true)" />
      </div>
      <div class="form-group">
        <label for="email">{{getText('Email', true)}}</label>
        <input type="text" name="email" id="email" required :placeholder="getText('Email',true)" />
      </div>
      <div class="form-group">
        <label for="subject">{{getText('Subject', true)}}</label>
        <input type="text" name="subject" id="subject" required :placeholder="getText('Subject',true)" />
      </div>
      <div class="form-group">
        <label for="message">{{getText('Message', true)}}</label>
        <textarea name="message" id="message" required :placeholder="getText('Message',true)"></textarea>
      </div>
      <div class="form-group">
          <input type="checkbox" name="subscibeToNewsLetter" id="subscibeToNewsLetter" /> <label for="subscibeToNewsLetter">{{ getText('Subscribe to newsletter',false) }}</label>
      </div>
      <div v-if="data.errorText != null" class="error-messages">
          <div class="error-message">{{ data.errorText }}</div>
      </div>
      <div class="form-group center-text">
          <input type="submit" :value="getText('Send', false)" class="btn" />
      </div>
    </form>
  </div>
  <div v-else>
      Mail sent
  </div>
</template>
<script setup lang="js">
import { onMounted, reactive } from 'vue'
import CmsApi from "../apis/cms";
import { useDictionaryStore } from '@/stores/dictionary'
const dictionaryStore = useDictionaryStore()

const data = reactive({
    errorText: null,
    mailSent: false
});

function getText (key, mandatory) {
  let txt = dictionaryStore.getText(key) + (mandatory ? " *" : "");
  return txt;
}

function submit(event) {
    if(event != null)
    {
        const {companyname,name, email, subject, message, subscibeToNewsLetter } = Object.fromEntries(new FormData(event.target));
        data.companyname = companyname;
        data.name = name;
        data.email = email;
        data.subject = subject;
        data.message = message;
        data.subscibeToNewsLetter = subscibeToNewsLetter == "on" ? true : false;
        CmsApi.sendMail({companyname:data.companyname,name:data.name, email:data.email, phone:"", subject:data.subject, message:data.message, subscibeToNewsLetter:data.subscibeToNewsLetter}).then(response => {
            if(response.data.error == null)
            {
                data.mailSent = true;
                data.errorText = null;
            }
            else
            {
                data.errorText = response.data.error.message; 
            }
        })
    }
}

onMounted(() => {
    dictionaryStore.loadDictionary();
})
</script>
<style lang="css">
  .wide-form {
    width: 100%;
    max-width: 800px;   
  }
</style>
