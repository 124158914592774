import axios from 'axios';

var domain = window.location.hostname;
var port = window.location.port;

var url = '//' + domain;
if (port !== "") {
  url += ':' + port;
}

const Api = axios.create({
  baseURL: url + '/Umbraco/Api/',
})

export default Api;