<template>
  <form method="post" @submit.prevent="submit" class="contact-form">
    <div class="title">
        <h2>{{ getText('Order online demo') }}</h2>
   </div>
   <div class="form-group">
        <input type="text" name="companyName" required :placeholder="getText('Enter companyname')" />
    </div> 
    <div class="form-group">
        <input type="text" name="name" required :placeholder="getText('Enter name')" />
    </div>
    <div class="form-group">
        <input type="text" name="email" minlength="3" required :placeholder="getText('Email')" />
    </div>
    <div class="form-group">
      <div>
        <input type="radio" id="Microsoft Teams" name="onlinedemo" value="Microsoft Teams" v-model="picked" />
        <label for="Microsoft Teams">Microsoft Teams</label>
      </div>  
      <div>
        <input type="radio" id="Google Meet" name="onlinedemo" value="Google Meet" v-model="picked"/>
        <label for="Google Meet">Google Meet</label>
      </div>
      <div>
        <input type="radio" id="Zoom" name="onlinedemo" value="Zoom" v-model="picked" />
        <label for="Zoom">Zoom</label>
      </div>
      <div>
        <input type="radio" id="Slack Huddle" name="onlinedemo" value="Slack Huddle" v-model="picked" required :placeholder="getText('Prefered meeting app')" />
        <label for="GSlack Huddle">Slack Huddle</label>
      </div>
      <div class="form-group center-text">
        <input type="submit" :value="getText('Order online demo')" class="btn" />
      </div>
    </div>
  </form>
</template>
<script setup lang="js">
import { onMounted, reactive } from 'vue';
import CmsApi from "../apis/cms";
import { useDictionaryStore } from '@/stores/dictionary';

const dictionaryStore = useDictionaryStore()

const data = reactive({
    companyName: '',
    name: '',
    email: null,
    preferedMeetingApp: null,
    errorText: null,
    translateErrorText: false
});

function getText (key) {
    return dictionaryStore.getText(key);
}

function submit(event) {
    if(event != null)
    {
        const {companyname, name, email, preferedMeetingApp } = Object.fromEntries(new FormData(event.target));
        data.companyname = companyname;
        data.name = name;
        data.email = email;
        data.preferedMeetingApp = preferedMeetingApp;
        CmsApi.orderOnlineDemo({companyname:data.companyname, name:data.name, email:data.email, preferedMeetingApp: data.preferedMeetingApp}).then(response => {
            if(response.data.error == null)
            {
                data.mailSent = true;
                data.errorText = null;
            }
            else
            {
                data.errorText = response.data.error.message; 
            }
        })
    }
}

onMounted(() => {
  dictionaryStore.loadDictionary();
})
</script>