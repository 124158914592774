import { defineStore } from "pinia";
import IAApi from "../apis/ia";

export const useFeatureStore = defineStore("feature", {
  state: () => ({
    features: [],
    featureTotal: 0,
    featureCurrency: "DKK"
  }),

  getters: {
    getFeatures: (state) => {
      return state.features;
    },

    getFeaturesTotal: (state) => {
      return state.featureTotal;
    },

    isLoaded(state)
    {
      return state.features = null & state.features?.length > 0
    }
  },

  actions: {
    loadFeatures() {
      if(this.features == null || this.features.length === 0)
      {
        IAApi.getFeatures().then(response => {
          this.features = response.data;
        }).then(() => {
          this.recalculate();
        })
      }
    },

    updateUnitCount(featureId, priceIndex, newUnitCount)
    {
      console.log(featureId, priceIndex, newUnitCount);
      const feature = this.features.find((f) => f.uniqueId === featureId);
     
      if (feature) {
        const price = feature.prices[priceIndex];
        if (price) {
          price.unitCount = Math.abs(newUnitCount);
          this.recalculate();
        }
      }
    },

    recalculate() {
      this.featureTotal = 0;
      this.features.forEach(feature => {
        if(feature.selected)
        {
          this.featureTotal += feature.prices[0].basePrice + (feature.prices[0].additionalUnitPrice * feature.prices[0].unitCount)
        }
      });
    }
  },
});