<template>
  <div class="umb-element">
    <div class="card wide-form">
      <div class="title center-text">
        <h2>{{ getText('Price calculator') }}</h2>
      </div>
      <div v-for="feature in featureStore.getFeatures" :key="feature.Id" >
        <div class="feature-group">
          <div v-if="feature.mandatory">
            <input type="checkbox" :name="feature.name" :checked="true" disabled /> {{feature.displayName}} 
          </div>
          <div v-else>
            <input type="checkbox" v-model="feature.selected" v-on:change="recalculate()"/> {{feature.displayName}}
          </div>
          <div v-if="feature.prices[0].additionalUnitPrice != null && feature.prices[0].additionalUnitPrice > 0">
            <div class="subFeature">
              {{feature.prices[0].additionalUnitPriceLabel}}: <input type="number" min="1" v-model="feature.unitCount" v-on:change="featureStore.updateUnitCount(feature.uniqueId, 0,  feature.unitCount)"/>
            </div>
          </div>
          <div class="price">{{feature.prices[0].basePrice + (feature.prices[0].additionalUnitPrice * feature.prices[0].unitCount)}} {{ featureStore.featureCurrency }}</div>
        </div>
      </div>
      <div>
        <div class="total-price-group">
          <div class="total-label">Din pris pr måned</div>
          <div class="total-price">{{ featureStore.getFeaturesTotal }} {{ featureStore.featureCurrency }}</div>
          <div class="btn large-padding align-right">
            <a href="/opret-konto/">Opret konto</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="js">
import { onMounted } from 'vue'
import { useDictionaryStore } from '@/stores/dictionary'
import { useFeatureStore } from '@/stores/featurestore'

const dictionaryStore = useDictionaryStore();
const featureStore = useFeatureStore();

/*
const data = reactive({
    features: []
});
*/

function recalculate()
{
  featureStore.recalculate();
}

function getText (key) {
    return dictionaryStore.getText(key);
}

onMounted(() => {
  dictionaryStore.loadDictionary();
  featureStore.loadFeatures();
})
</script>

<style lang="css" scoped>
.feature-group
{
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  margin:0 0;
  padding:10px 10px;
}

.feature-group div
{
  float: left;
}

.feature-group input[type='checkbox'] {
  padding: 10px;
}

.feature-group .subFeature {
  margin-left: 10px;
}

.feature-group input[type='number'] {
  width:68px;
  color:#888;
  margin: 0 0 0 10px;
  padding: 2px 10px;
  background-color: #f6f6f6;
  border:1px solid #ddd;
  border-radius: 6px;
  font-size: inherit;
  outline: none;
}

.feature-group .price {
  position: relative;
  float: right;
}

.total-price-group
{
  border-top: 1px solid #ddd;
  margin:14px 0;
  padding:5px 16px;
}

.total-price-group .total-label {
  float: left;
  font-weight: bold;
}

.total-price-group .total-price {
  float: right;
  font-weight: bold;
}

</style>